<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Unique Events (Index)
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table
            id="unique-tickets"
            class="border-collapse stripe hover w-full text-sm"
            v-if="data.length"
          >
            <thead>
              <tr>
                <th>
                  Event
                </th>
                <th>
                  Venue
                </th>
                <th>
                  City
                </th>
                <th>
                  State
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr v-for="tr in data" :key="tr.id" id="utix-data-wait">
                <td>
                  {{ tr.event }}
                </td>
                <td>
                  {{ tr.venuename }}
                </td>
                <td>
                  {{ tr.city }}
                </td>
                <td>
                  {{ tr.state }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!data.length" class="text-center">
            {{ loading }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "UniqueTix",
  data() {
    return {
      loading: "Please wait...",
      data: []
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/ticket/get-unique`)
        .then(res => {
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          var self = this;
          var uexistTix = setInterval(function() {
            if (self.jQuery("#utix-data-wait").length) {
              clearInterval(uexistTix);
              self.jQuery("#unique-tickets").DataTable({
                autoWidth: false,
                aaSorting: [0, 1, 2, 3],
                responsive: true,
                aLengthMenu: [100],
                processing: true,
                language: {
                  processing:
                    "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>"
                },
                serverSide: true,
                ajax: {
                  url: `${self.$api}/ticket/get-unique`,
                  dataFilter: function(data) {
                    const records = JSON.parse(data);
                    const json = records.data;
                    return JSON.stringify({
                      recordsTotal: records.total,
                      recordsFiltered: records.total,
                      data: json
                    }); // return JSON string
                  }
                },
                deferLoading: res.data.total,
                columns: [
                  {
                    data: "event",
                    title: "Event",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}"
                        target="_blank"
                        >${data}</a
                      >`;
                    }
                  },
                  { data: "venuename" },
                  { data: "city" },
                  { data: "state" }
                ]
              });
            }
          }, 100);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    }
  }
};
</script>
<style scoped>
/*Overrides for Tailwind CSS */

/*Form fields*/
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568; /*text-gray-700*/
  padding-left: 1rem; /*pl-4*/
  padding-right: 1rem; /*pl-4*/
  padding-top: 0.5rem; /*pl-2*/
  padding-bottom: 0.5rem; /*pl-2*/
  line-height: 1.25; /*leading-tight*/
  border-width: 2px; /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7; /*border-gray-200*/
  background-color: #edf2f7; /*bg-gray-200*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #ebf4ff; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  border-bottom: 1px solid #e2e8f0; /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important; /*bg-indigo-500*/
}
</style>
